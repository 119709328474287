@import 'styles/import.scss';

.text {
  margin-bottom: 40px;
  input {
    cursor: text;
    width: 100%;
  }

  label {
    @include tn(color, 0.5s, 0s, ease-in-out);
    margin-bottom: 4px;
  }

  &:hover {
    label {
      color: $green;
    }
  }

  &.textArea {
    margin-bottom: 120px;
    width: 100%;
  }

  &.konto {
    @include media-breakpoint-up(md) {
      padding: 0 12px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 16px;
    }
  }

  &.isHidden {
    display: none;
  }

  &.isError {
    label,
    label::after {
      color: $red_error;
    }

    input,
    textarea {
      border-color: $red_error;
    }
    :local(.textContainer) {
      &::after {
        background: $red_error;
      }
      &::before {
        background: $red_error_hover;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 48px;

    &.extraPadding,
    &.textArea {
      padding: 0 16px;
    }

    &.textArea {
      width: 100%;
    }
  }
}

.fullWidth {
  padding: 0;
  .text {
    width: 100%;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 100%;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.textContainer {
  @include green-underline;
}

.inputWrapper {
  position: relative;
}

.passwordVisible {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.description {
  @include text-xs;
  color: $grey200;
  margin-top: 6px;
}
