@import 'styles/import.scss';

.richtext {
  &.center {
    text-align: center;
  }

  // &.dropcap {
  //   &::first-letter {
  //     @apply float-left mr-4 text-8xl font-bold;
  //   }
  // }

  /* List Styles */
  // &.ul,
  // &.ol {
  //   @apply mb-4;

  //   & li {
  //     @apply mb-2 relative pl-6 list-none;
  //   }

  //   & ul,
  //   & ol {
  //     @apply ml-6 mb-0;
  //   }
  // }

  /* Unordered Lists */

  /* Ordered Lists */
  // &.ol {
  //   counter-reset: item;

  //   & > li {
  //     &::before {
  //       @apply absolute block left-0 italic;

  //       content: counter(item) '. ';
  //       counter-increment: item;
  //       margin-left: 2px;
  //     }
  //   }
  // }
}
