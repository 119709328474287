@import 'styles/import.scss';

.fullscreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 240px;
    height: 240px;
  }
}

.spinner {
  width: 120px;
  height: 120px;
}

.spinner svg {
  animation: rotate 1.5s linear infinite;
  height: 100%;
  width: 100%;
}

.spinner circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite 0s,
    color 6s ease-in-out infinite -0.75s;
  stroke-linecap: square;
  fill: none;
  stroke-width: 2;
}

.isSmall {
  svg {
    width: 60px;
    height: 60px;
  }
  circle {
    animation: dash 1.5s ease-in-out infinite 0s,
      color2 6s ease-in-out infinite -0.75s;
  }
  .spinner {
    width: 60px;
    height: 60px;
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $green_light;
  }
}

@keyframes color2 {
  100%,
  0% {
    stroke: $white;
  }
}
