@import 'styles/import.scss';

.container {
  @include make-container();

  max-width: $container-max-width;

  &.large {
    max-width: $container-outer-max-width;
    padding: 0;
  }

  padding-bottom: 120px;
  &.removePaddingBottom {
    padding-bottom: 0;
  }
  &.changePaddingBottom {
    padding-bottom: 80px;
  }
  &.constantPaddingBottom {
    padding-bottom: 120px;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 160px;
    &.changePaddingBottom {
      padding-bottom: 120px;
    }
  }
  @include media-breakpoint-up(lg) {
    &.changePaddingBottom {
      padding-bottom: 160px;
    }
  }
}
