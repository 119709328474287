@import 'styles/import.scss';

.header {
  @include tn(
    (background, transform),
    (100ms, 300ms),
    (ease-in, ease-in),
    (0s, 0ms)
  );

  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 100;
  background: $blue_light;

  &.hasbg {
    background: $white;
  }

  &.hideNav {
    @include tn(
      (background, transform),
      (100ms, 300ms),
      (ease-in, ease-in),
      (0s, 0ms)
    );
    transform: translateY(-100%);
  }

  &.showNav {
    transform: translateY(0);
  }

  &.menuOpen {
    background: $blue_dark;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 101;
    width: 100%;
    transition-duration: 300ms;

    &.hideNav {
      transform: translateY(0);
    }
  }

  .inner {
    @include make-container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height-sm;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }

  .logo {
    height: 16px;
    width: auto;
  }

  :global(.hamburger) {
    padding: 15px 0;
  }

  @include media-breakpoint-up(lg) {
    .profileIcon {
      cursor: pointer;
    }

    .logo {
      height: 18px;
    }

    :global(.hamburger) {
      position: relative;
      right: -15px;
    }

    .inner {
      max-width: $container-max-width;
      margin: 0 auto;
      height: $header-height;
    }

    .logo {
      height: 20px;
    }

    .links {
      display: flex;
      align-items: center;
    }

    .link {
      @include body-l;
      color: white;
      margin-left: 20px;
      cursor: pointer;
      overflow-x: hidden;
      display: inline-block;
      position: relative;

      svg {
        @include tn(transform);
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }

      &:after {
        @include tn(transform);
        content: '';
        width: calc(100% - 20px);
        transform: translateX(-102%);
        background: white;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 1px;
      }

      &.active,
      &:hover {
        &::after {
          transform: translateX(0);
        }
      }

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    a.link::after {
      width: 100%;
    }
  }
  @include media-breakpoint-up(xl) {
    .link {
      @include text-md;
      margin-left: 32px;
    }
  }
}
