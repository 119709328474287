@import './import.scss';
@import 'common/reboot.scss';
@import 'common/helper.scss';
@import 'config/variables.scss';
@import 'mixins/grid.scss';
@import 'mixins/typography';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'react-big-calendar/lib/sass/styles';

$hamburger-layer-border-radius: 0px;
$hamburger-layer-height: 2px;
$hamburger-layer-width: 24px;
$hamburger-layer-spacing: 6px;
$hamburger-active-layer-color: white;
$hamburger-layer-color: white;
$hamburger-hover-opacity: 1;
@import '~hamburgers/_sass/hamburgers/hamburgers';

/**
 * Global
 */
a {
  @include tn(color);

  &:hover {
    text-decoration: none;
    // color: $p1;
  }

  &:focus {
    outline: 0;
  }
}

button,
input[type='button'] {
  border: 0;
  background: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }
}

/**
 * Block editor color palette utilities
 * @see https://git.io/JeyD6
 */

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 16px;
  font-weight: 400;
}

body {
  @include font-family;
  @include text-md;

  color: $black;
}

body ::selection {
  background-color: $selection;
}

body.no-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

body {
  .rbc-overlay {
    padding: 16px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    padding: 0;
    background-color: transparent;
  }
  .rbc-overlay-header {
    border-bottom: 1px solid $grey25;
    @include text-xs;
    font-family: $font-mono;
    color: $grey200;
    padding: 0 0 16px;
    margin: 0 0 16px;
  }
  .rbc-overlay > * + * {
    margin-top: 6px;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
  height: 0;
}

.is--full-width {
  width: 100vw;
  z-index: 1;
  position: relative;
  margin-left: calc(-50vw + 50%);
}
