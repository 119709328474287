@mixin form-fields {
  input:not([type='checkbox']),
  select,
  textarea {
    &:hover {
      border-color: $green;
      cursor: pointer;
    }
  }
  input:not([type='checkbox']),
  select,
  textarea {
    display: block;
    height: 48px;
    outline: 0;
    width: 100%;
    padding: 0 8px;
    background: $grey1;
    cursor: text;
    @include text-md;
    @include input-placeholder {
      color: $grey100;
      @include text-md;
    }

    &:hover {
      cursor: text;
    }
  }
  textarea {
    padding-top: 8px;
    min-height: 250px;
    height: auto;
  }

  label,
  label::after {
    color: $grey200;
    @include text-xs;
    font-family: $font-mono;
  }
}
