@import 'styles/import.scss';

.main {
  min-height: 100vh;
  overflow-x: hidden;

  &.addMarginNegative {
    margin-bottom: -40px;
  }
  @include media-breakpoint-up(md) {
    &.addMarginNegative {
      margin-bottom: -80px;
    }
  }
}

.teaser {
  @include heading-2;
  background: $blue_light;
  color: white;
  padding-top: 152px;
  padding-bottom: 168px;
  margin-bottom: 120px;

  @include media-breakpoint-up(md) {
    padding-bottom: 184px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 200px;
    margin-bottom: 160px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 184px;
    padding-bottom: 240px;
  }
  @include media-breakpoint-up(xxl) {
    padding-top: 200px;
  }
}

.pageStaticWrapper {
  padding-bottom: 32px;

  @include media-breakpoint-up(md) {
    @include make-col(8, 12);
    margin-left: auto;

    padding-bottom: 96px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
  }

  h2 {
    @include heading-6;

    margin-bottom: 24px;
  }

  h3 {
    @include text-xl;

    margin-bottom: 16px;
    margin-top: 56px;
  }

  h4 {
    @include text-lg;

    margin-bottom: 16px;
  }

  hr {
    position: relative;
    width: calc(100vw - 40px);
    left: calc((100% - 100vw + 40px));
    margin-bottom: 48px;
    margin-top: 80px;

    @include media-breakpoint-up(md) {
      width: calc(100vw - 64px);
      left: calc((100% - 100vw + 64px));
    }

    @include media-breakpoint-up(lg) {
      width: calc(100vw - 80px);
      left: calc((100% - 100vw + 80px));
    }

    @include media-breakpoint-up(xl) {
      width: calc(100vw - 96px);
      left: calc((100% - 100vw + 96px));
    }

    @media (min-width: $container-max-width) {
      left: 0;
      transform: translateX(-50%);
      max-width: $container-max-width - 96px;
    }
  }

  p {
    @include text-md;

    margin-bottom: 28px;
  }

  ul {
    @include formatted-li;
    li {
      position: relative;
      padding-left: 28px;
      display: block;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  a {
    @include gradient-underline;

    @include tn(color, 0.5s, 0s, ease-in-out);

    color: $black;
    word-break: break-word;

    &::after {
      background: $green_light;
    }

    &:hover {
      color: $green;
    }
  }
}
