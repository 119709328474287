@import 'styles/import.scss';

.form {
  @include hide-scrollbar;
  @include form-fields;
  position: fixed;
  top: 0;
  right: 0;
  background: $white;
  z-index: 102;
  margin-left: auto;
  transform: translateX(100%) translateZ(0);
  overflow: hidden scroll;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  &:global(.is--inactive) {
    transition: transform 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s;
  }
  &:global(.is--active) {
    right: 0;
    left: auto;
    transform: translateX(0%) translateZ(0);
    transition: transform 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s;
    :local(+ .closeForm) {
      display: block;
    }
  }

  input:not([type='checkbox']),
  select,
  textarea {
    &:hover {
      border-color: $green;
      cursor: text;
    }
  }
  input:not([type='checkbox']),
  select,
  textarea {
    display: block;
    height: 48px;
    outline: 0;
    width: 100%;
    @include text-md;
    @include input-placeholder {
      color: $grey100;
      @include text-md;
    }
  }
  textarea {
    padding-top: 8px;
    min-height: 250px;
    height: auto;
  }
  label {
    margin-bottom: 0;
  }
  label,
  label::after {
    color: $grey200;
    @include text-xs;
    font-family: $font-mono;
  }
}

.formContainer {
  padding: 8px 20px 120px;

  @include media-breakpoint-up(md) {
    padding: 8px 48px 120px;
  }

  @include media-breakpoint-up(lg) {
    padding: 8px 64px 120px;
  }

  @include media-breakpoint-up(xl) {
    padding: 16px 72px 120px;
  }

  @include media-breakpoint-up(xxl) {
    padding: 32px 80px 120px;
  }
}

.iconWrapper,
.form {
  @include media-breakpoint-up(md) {
    width: 493px;
  }

  @include media-breakpoint-up(lg) {
    width: 501px;
  }

  @include media-breakpoint-up(xl) {
    width: 628px;
  }

  @include media-breakpoint-up(xxl) {
    width: 704px;
  }

  &.registrierenForm {
    @include media-breakpoint-up(md) {
      width: 704px;
    }

    @include media-breakpoint-up(lg) {
      width: 864px;
    }

    @include media-breakpoint-up(xl) {
      width: 960px;
    }

    @include media-breakpoint-up(xxl) {
      width: 1024px;
    }
  }
}

.iconWrapper {
  position: sticky;
  z-index: 102;
  height: 72px;
  top: 0;
  right: 0;
  background-color: $white;

  @include media-breakpoint-up(lg) {
    height: 88px;
  }

  svg {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      right: 32px;
    }
    @include media-breakpoint-up(lg) {
      top: 24px;
      right: 40px;
    }
    @include media-breakpoint-up(xl) {
      right: 48px;
    }
  }
}

.page {
  position: static;
  margin-left: 0;
  height: auto;
  transform: translateX(0);
  z-index: 2;
  padding: 64px 32px;

  .iconWrapper {
    display: none;
  }

  .formContainer {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    padding: calc($header-height-sm + 64px) 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: calc($header-height + 96px) 40px 80px;
  }

  @include media-breakpoint-up(xxl) {
    padding: calc($header-height + 96px) 48px 120px;
  }
}
