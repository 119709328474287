@import 'styles/import.scss';

.navigation {
  @include tn(opacity visibility);

  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-height: calc(100vh - #{$header-height-sm});
  z-index: -1;
  display: none;
  position: absolute;
  top: $header-height-sm;
  left: 0;
  right: 0;

  &.menuOpen {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    display: block;

    .menu {
      display: flex;
      padding: 0;
      width: 100%;
      height: 100vh;

      &::after,
      &::before {
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-delay: 1s;
        animation-fill-mode: forwards;
      }
    }
  }

  .menu {
    padding-top: 32px;
    margin: 0;
    &::after,
    &::before {
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 96px;
      max-height: 200px;
      background: linear-gradient(
        180deg,
        rgba(5, 30, 78, 0) 0%,
        #051e4e 63.54%
      );
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-container;
    opacity: 1;
    visibility: visible;
    width: auto;
    display: block;
    z-index: 1;
    width: 100%;
    top: $header-height;
    max-height: calc(100vh - #{$header-height});

    margin-left: auto;
    margin-right: auto;
    .menu {
      display: flex;
      padding: 0;
      width: 100%;

      &::after {
        height: 20%;
      }

      &::before {
        height: 10%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        max-height: 80px;
        z-index: 1;
        background: linear-gradient(0deg, transparent 0%, #051e4e 100%);
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
