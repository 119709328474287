.is--hidden-sm {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.is--hidden-md-up {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.is--hidden-lg {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.is--hidden-lg-up {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}
