@import 'styles/import.scss';

.button {
  @include tn((background, color), 0.5s, 0s, (ease-in, ease-in-out));
  @include text-md;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.primary {
  color: $green_light;
  padding: 0;

  div:first-child {
    @include hover-border;

    &::after {
      background-color: $green;
    }
  }

  &.isSmall {
    @include text-sm;

    :local(.icon) {
      height: 32px;
      width: 32px;
    }
  }
  .icon {
    margin-left: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg * {
      @include tn((background, stroke));
    }
  }
  &:disabled {
    cursor: auto;
    pointer-events: none;
    .icon {
      svg * {
        stroke: $grey25;
      }
    }
  }
  &:hover {
    color: $green;

    div:first-child {
      border-color: $green;
    }

    svg * {
      stroke: $green;
    }
  }
}

.filled {
  padding: 14px 20px;
  background-color: $green_light;
  color: $white;

  .icon {
    margin-left: 24px;
  }

  &:hover {
    background-color: $green;
    color: $white;
  }

  &.isSmall {
    @include text-sm;
    padding: 11px 20px;
  }

  &.disabled {
    background-color: $grey25;
    color: $white;
    cursor: auto;
    pointer-events: none;
  }

  &.isLoading {
    background-color: $green_light;
    position: relative;

    > div {
      &:first-child {
        position: absolute;
        visibility: visible;
        div {
          visibility: visible;
        }
        svg * {
          fill: transparent;
        }
      }
    }

    div {
      visibility: hidden;
    }

    &:hover {
      background-color: $green_light;
    }
  }
}
