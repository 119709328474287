@mixin formatted-li {
  li {
    position: relative;
    margin-left: 28px;
    margin-bottom: 12px;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 16px;
      background-color: $green_light;
      left: -28px;
      margin-top: 13px;
      display: block;
      flex-shrink: 0;
    }
  }
}
