@import 'styles/import.scss';

.cookies {
  &:after {
    content: '';
    position: fixed;
    top: 0;
    background-color: rgba($blue_dark, 0.95);
    opacity: 0;
    @include tn(opacity, 0.5s, 0s, ease-in-out);
    width: 100%;
    z-index: 101;
    pointer-events: none;
    height: 100%;
  }
  &.isInActive {
    :local(.wrapper) {
      transition: transform 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s;
    }
  }
  &.isActive {
    overflow-y: scroll;

    :local(.wrapper) {
      right: 0;
      left: auto;
      transition: transform 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s;

      transform: translateX(0%);
      :local(.closeForm) {
        display: block;
      }
    }
    &::after {
      height: 100%;
      z-index: 101;
      width: 100%;
      opacity: 1;
    }
  }
}

.title {
  @include heading-5;
  margin-bottom: 48px;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: $white;
  z-index: 102;
  margin-left: auto;
  transform: translateX(100%);
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.formContainer {
  padding: 0 20px 120px;

  @include media-breakpoint-up(md) {
    padding: 16px 48px 120px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @include media-breakpoint-up(xl) {
    padding: 16px 72px 120px;
  }
  @include media-breakpoint-up(xxl) {
    padding: 32px 80px 120px;
  }
}

.iconWrapper,
.wrapper {
  @include media-breakpoint-up(md) {
    width: 704px;
  }
  @include media-breakpoint-up(lg) {
    width: 864px;
  }
  @include media-breakpoint-up(xl) {
    width: 960px;
  }
  @include media-breakpoint-up(xxl) {
    width: 1024px;
  }
}

.oneCookieWrapper {
  margin-bottom: 48px;

  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.heading {
  @include text-xl;
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.switchHelper {
  margin-right: 8px;

  @include media-breakpoint-up(md) {
    margin-right: 24px;
  }
}

.icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: -7px;
  left: 5px;
}

.text {
  margin-bottom: 16px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.buttonWrapper {
  padding-top: 16px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.expand {
  color: $green_light;
  display: flex;
  align-items: center;
  @include tn(color, 0.5s, 0s, ease-in-out);
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: $green;
    svg {
      path {
        fill: $green;
      }
    }
  }

  svg {
    @include tn(transform);

    margin-left: 8px;

    path {
      fill: $green_light;
    }
  }

  &.isExpanded {
    svg {
      transform: rotate(180deg);
    }
  }
}

.tableWrapper {
  overflow: auto;
}

.table {
  margin-top: 20px;
  border-bottom: 1px solid $grey25;
  width: 100%;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  tr {
    &:last-child {
      th,
      td {
        padding-bottom: 0;
      }
    }
  }

  th {
    @include text-sm;
    padding-bottom: 8px;
    vertical-align: top;
  }

  tbody {
    padding: 24px 0;
    display: block;
    border-top: 1px solid $grey25;

    td {
      @include text-sm;
      color: $grey200;
      padding-left: 24px;
      padding-bottom: 8px;
      vertical-align: top;
    }
  }

  @include media-breakpoint-up(md) {
    tbody {
      td {
        padding-left: 56px;
      }
    }
  }
}

.iconWrapper {
  position: sticky;
  z-index: 102;
  height: 72px;
  top: 0;
  right: 0;
  background-color: $white;
  @include media-breakpoint-up(lg) {
    height: 88px;
  }
  svg {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      right: 32px;
    }
    @include media-breakpoint-up(lg) {
      top: 24px;
      right: 40px;
    }
    @include media-breakpoint-up(xl) {
      right: 48px;
    }
  }
}
