@import 'styles/import.scss';

.item {
  position: relative;
  margin-bottom: 24px;

  &.hasChildren {
    svg {
      @include tn(transform);
      width: 36px;
      height: 36px;
      margin-left: 15px;
    }

    &.subMenuOpen {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.link {
  @include text-xl;
  @include tn(opacity);

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

@include media-breakpoint-up(lg) {
  .item {
    @include tn(opacity);
    margin-right: 32px;

    svg {
      width: 40px;
      height: 40px;
    }

    &.opaque {
      opacity: 0.6;
    }

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1 !important;
    }
  }

  .link {
    @include text-xl;
    margin-bottom: 40px;
  }
}
