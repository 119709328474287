@import 'styles/import.scss';

.cookieBanner {
  position: fixed;
  width: 100%;
  bottom: 24px;
  left: 0;

  &.fadeIn {
    animation: fadeIn 1s forwards;
  }

  &.fadeOut {
    opacity: 0;
    animation: fadeOut 1s forwards;
  }

  > div {
    max-width: initial;
  }

  @include media-breakpoint-up(md) {
    bottom: 32px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  to {
    z-index: 10;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    z-index: 10;
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }
}

.text {
  margin-bottom: 24px;
  @include text-sm;
  @include media-breakpoint-up(xl) {
    margin-bottom: 16px;
  }
}

.inner {
  background: $white;
  box-shadow: 0px 4px 4px rgba(14, 14, 15, 0.03),
    0px 8px 12px rgba(14, 14, 15, 0.06);
  padding: 16px 16px 20px;

  @include media-breakpoint-up(md) {
    width: 340px;
  }
  @include media-breakpoint-up(lg) {
    width: 380px;
  }
  @include media-breakpoint-up(xl) {
    width: 537px;
  }
  @include media-breakpoint-up(xxl) {
    width: 541px;
  }
}

.button {
  width: 100%;
  margin-bottom: 16px;
  @include media-breakpoint-up(xl) {
    width: auto;
    margin-bottom: 0;
  }
}

.actions {
  display: flex;
  @include text-xs;
  justify-content: space-between;
  align-items: flex-end;
  div,
  a {
    height: fit-content;
    color: $black;
    @include gradient-underline;
    padding-bottom: 0;
    cursor: pointer;
  }
  @include media-breakpoint-up(md) {
    justify-content: initial;

    column-gap: 9px;
  }
  @include media-breakpoint-up(lg) {
    column-gap: 28px;
  }
  @include media-breakpoint-up(xl) {
    column-gap: 12px;
  }
}

.row {
  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
