@import 'styles/import.scss';

.langswitcher {
  margin-bottom: 16px;
  color: white;
  display: flex;
  a {
    display: flex;
    align-items: center;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    path {
      fill: white;
    }
  }

  &:hover {
    :local {
      .language {
        &::after {
          transform: translateX(0);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    margin-left: 16px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 40px;
  }
}

.language {
  cursor: pointer;
  display: inline-block;
  position: relative;
  opacity: 1;
  color: $white;
  overflow-x: hidden;
  &:after {
    @include tn(transform);
    content: '';
    width: calc(100%);
    transform: translateX(-102%);
    background: white;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 1px;
  }
}
