@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  &:input-placeholder {
    @content;
  }

  &::input-placeholder {
    @content;
  }
}
