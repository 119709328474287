@import 'styles/import.scss';

.fixed {
  position: relative;
  top: $header-height-sm + 12px;
  width: 100%;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: calc($header-height + 10px);
  }
}

.wrapper {
  padding: 24px;
  border-radius: 8px;
  background-color: $s5;

  @include media-breakpoint-up(md) {
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) {
    padding: 32px 16px;
  }
}

.isHidden {
  display: none;
}

.closeIcon {
  position: absolute;
  top: 26px;
  right: 26px;
  flex-shrink: 0;
  cursor: pointer;

  path {
    fill: $s2;
  }

  &:hover {
    path {
      fill: $p1;
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 8px;
  }
}

.heading {
  display: flex;
  margin-bottom: 16px;

  @include heading-xs;

  @include media-breakpoint-up(md) {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

.textLinkWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
}

.link {
  @include caption-b;

  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
  color: $s2;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    &::after {
      content: '';
      border-left: 1px solid $s2;
      width: 0;
      height: 16px;
      background-color: $s2;
      margin-left: 8px;
      flex-shrink: 1;
    }
  }
}

.text {
  @include heading-xs;

  font-weight: 400;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    padding-right: 20px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      border-left: 1px solid $s2;
      width: 0;
      height: 16px;
      background-color: $s2;
      margin-right: 32px;
      flex-shrink: 1;
    }
  }
}

.infoIcon {
  margin-right: 8px;
}
