@import 'styles/import.scss';

.profile {
  align-self: center;

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($blue_dark, 0.95);
    opacity: 0;
    z-index: 101;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  &.formOpen:after {
    opacity: 1;
  }
}

.profileIcon {
  display: none;
  color: $white;
  cursor: pointer;

  svg {
    display: inline-block;
    margin-left: 24px;
    margin-right: 8px;
  }
  span {
    @include text-md;
    position: relative;
    display: inline-block;
    overflow-x: hidden;
    &:after {
      @include tn(transform);
      content: '';
      width: 100%;
      transform: translateX(-100%);
      background: white;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 1px;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;

    svg {
      margin-left: 20px;
    }

    span {
      @include body-l;
    }

    &.active,
    &:hover {
      span {
        &::after {
          transform: translateX(0);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    svg {
      margin-left: 24px;
    }
    span {
      @include text-md;
    }
  }
}
.errorMessage {
  z-index: 555;
  position: absolute;
  right: 15%;
}

.honey {
  display: none !important;
}

.title {
  @include heading-3;
  padding-bottom: 64px;
}

.subtitle {
  @include text-md;
  padding-bottom: 64px;
}

.fields {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  width: 100%;
}

.forgotPassword {
  @include text-sm;
  margin-top: -24px;
}

.underlindedButton {
  div {
    @include text-sm;
    @include gradient-underline;
    cursor: pointer;
    height: fit-content;
    color: $black;
  }
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.halfLength,
.passwords {
  @include media-breakpoint-up(xl) {
    gap: 32px;
  }
}

.halfLength {
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 24px;
  }
}

.passwords {
  margin-bottom: -48px;

  @include media-breakpoint-up(md) {
    display: flex;
    gap: 32px;
  }

  @include media-breakpoint-up(lg) {
    gap: 24px;
  }
}

.titleWrapper {
  padding-bottom: 40px;

  :local(.formTitle) {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.formSubtitle,
.formTitle {
  @include text-xl;
}

.formTitle {
  padding-bottom: 40px;
}

.questionWrapper {
  @include text-sm;
  display: flex;
  gap: 4px;
}

.passwordPolicy {
  @include text-xs;
  color: $grey200;
  padding-top: 6px;
}

.spamText {
  a {
    color: $black;
    @include gradient-underline;
  }
}

.button {
  margin-top: 48px;
}
