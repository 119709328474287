@import 'styles/import.scss';

.checkbox {
  display: flex;
  align-items: flex-start;
  @include text-sm;
  padding-top: 24px;
  margin-bottom: 64px;
  &.isError {
    input {
      border-color: $red_error;
    }
    input {
      &:hover {
        border-color: $red_error_hover;
      }
    }
  }

  input {
    cursor: pointer;
    appearance: none;
    margin-right: 12px;
    width: 20px;
    height: 20px;
    border: 1px solid $green_light;
    flex-shrink: 0;
    margin-top: 6px;
    &:hover {
      border-color: $green;
    }
    &:checked:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-top: -1px;
      margin-left: -1px;

      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1519_6588' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1519_6588)'%3E%3Cpath d='M8.33329 13.375L5.29163 10.3333L6.16663 9.45833L8.33329 11.625L13.8333 6.125L14.7083 7L8.33329 13.375Z' fill='%232FBD77'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.label {
  p {
    margin-bottom: 0;
  }
  a {
    @include gradient-underline;
    color: $black;

    &:hover {
      color: $green;
    }
    &:after {
      background: $green_light;
    }
  }
}

.login {
  &:last-child {
    padding-top: 16px;
    border-top: 0;
  }
}

.borderFree {
  align-items: center;
  border-top: 0;
  padding-top: 0;
  margin-bottom: 40px;

  > input {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
    padding: 0 16px;
  }
}
