@import 'styles/import.scss';

.footer {
  background: $blue_dark;
  padding: 64px 0 20px;
  color: $white;
  margin-top: 40px;
  &.removeMarginTopFooter {
    margin-top: 0;
  }
  @include media-breakpoint-up(md) {
    margin-top: 80px;
  }
}

.paymentMethods {
  margin-top: 32px;
  &.hideOnMobile {
    display: none;
  }
}

.legal {
  color: $white;
}

.section {
  margin-bottom: 48px;
}

.headline {
  font-family: $font-mono;
  @include text-xs;

  color: rgba($white, 0.4);
  margin-bottom: 20px;
}

.linkLegal,
.link {
  @include hover-border;
  color: $white;
  display: inline-flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-left: 8px;
  }

  &:hover {
    color: white;
  }
}
.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linkLegal {
  @include text-xs;
  display: block;
  cursor: pointer;
}

.link {
  @include text-sm;
  &:last-child {
    margin-bottom: 0;
  }
}

.contact {
  @include text-sm;
  white-space: pre-line;
  margin-top: 48px;
  margin-bottom: 48px;

  div:first-child {
    margin-bottom: 16px;
  }

  a {
    @include hover-border;

    color: $white;
  }
}

.copyright {
  @include text-xs;
  margin-top: 32px;
}

.socialArrowIconWrapper {
  margin-left: 8px;
}

@include media-breakpoint-up(md) {
  .contactWrapper {
    @include make-col(3, 6);
  }

  .copyright {
    margin-top: 0;
  }

  .contact {
    margin-bottom: 80px;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 72px;
  }

  .section {
    @include make-col(3, 6);
    margin-bottom: 64px;
  }

  .legal {
    padding-top: 20px;
    border-top: 1px solid rgba($white, 0.2);
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex-wrap: wrap;
    :local(.paymentMethods) {
      display: none;
    }
  }

  .paymentMethods {
    margin-top: 0;
    &.hideOnMobile {
      display: block;
    }
  }

  .linkLegal {
    margin-bottom: 0;
    margin-left: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .inner {
      display: flex;
      margin-bottom: 112px;
    }
    .contactWrapper {
      @include make-col(4, 12);
    }
    .menu {
      flex-wrap: wrap;
      margin: 0 -32px;
      margin-left: auto;
      justify-content: flex-end;
    }
    .section {
      flex-basis: unset;
      max-width: unset;
      padding: 0 32px;
    }
  }
  @include media-breakpoint-up(xl) {
    .inner {
      margin-bottom: 160px;
    }
    .contactWrapper {
      @include make-col(3, 12);
    }
    .contact {
      margin-bottom: 0;
    }
    .menu {
      max-width: 100%;
      margin: 0 -30px;
      margin-left: auto;
      flex: initial;
    }
    .section {
      @include make-col(3, 6);
      flex: initial;
      max-width: 100%;
      padding: 0 48px;
      margin-bottom: 0;
    }
  }
}
